import React from 'react'
import { Link } from 'gatsby'
import SocialLinks from '../constants/socialLinks'
const Navbar = ({ toggle }) => {
  return (
    <nav className="navbar bg-teal is-fixed-top" role="navigation" aria-label="main navigation">
      <div className="container">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item nav-logo light-blue didact">
            hotbotproductions
          </Link>
        </div>
        <div className="navbar-menu navbar-menu--hb">
          {/* <ul className="navbar-start">
            <li className="navbar-item light-blue">store</li>
            <li className="navbar-item light-blue">music</li>
            <li className="navbar-item light-blue">demos</li>
            <li className="navbar-item light-blue">contact</li>
          </ul> */}
          <SocialLinks styleClass="navbar-end navbar-end--hb" />
        </div>
      </div>
    </nav>
  )
}

export default Navbar
