/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import Navbar from './Navbar'
import Sidebar from './Sidebar'
import Footer from './Footer'
import SeoHelmet from './SeoHelmet'
import {Helmet} from "react-helmet"
const Layout = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }
  return (
    <>
      <Helmet htmlAttributes={{lang: 'en'}}/>
      <SeoHelmet title="Home" />
      <Navbar toggle={toggle} />
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <main className="main">{children}</main>
      <Footer />
    </>
  )
}

export default Layout
