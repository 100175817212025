import React from "react"
import {
  FaSoundcloud,
  FaTiktok,
  FaYoutube,
  FaTwitch
} from "react-icons/fa"
const SocialLinks = ({ styleClass }) => {
  return (
    <ul className={styleClass}>
      <li className="navbar-item">
        <a href="https://soundcloud.com/hotbotpro" title="soundcloud" target="_blank" rel="noopener noreferrer">
          <FaSoundcloud className="social-icon light-blue" aria-hidden="true" tabIndex="-1"></FaSoundcloud>
        </a>
      </li>
      <li className="navbar-item">
        <a href="https://www.twitch.tv/hotbotproductions" title="twitch" target="_blank" rel="noopener noreferrer">
          <FaTwitch className="social-icon light-blue" aria-hidden="true" tabIndex="-1"></FaTwitch>
        </a>
      </li>
      <li className="navbar-item">
        <a href="https://www.tiktok.com/@hotbotproductions" title="tiktok" target="_blank" rel="noopener noreferrer">
          <FaTiktok className="social-icon light-blue" aria-hidden="true" tabIndex="-1"></FaTiktok>
        </a>
      </li>
      <li className="navbar-item">
        <a href="https://www.youtube.com/channel/UCAfgugm_MgSsyX31ganOdMQ" title="youtube" target="_blank" rel="noopener noreferrer">
          <FaYoutube className="social-icon light-blue" aria-hidden="true" tabIndex="-1"></FaYoutube>
        </a>
      </li>
    </ul>
  )
}
export default SocialLinks
